.searchbar-v1 {
	position: absolute;
	bottom: 70px;
	width: 100%;

	@media (max-width: 767px) {
		bottom: 30px;
	}

	.checkbox label:after {
		top: 2px;
	}

	@media (max-width: 576px) {
		padding: 0px 10px;
	}

	@media (max-height: 600px) {
		top: 350px;
	}

	.title {
		color: #fff;
		background-color: var(--color-secondary);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		padding: 10px 20px;
		opacity: 0.8;

	}

	.search-form-area {
		display: flex;
		-webkit-box-align: center;
		align-items: center;
		-webkit-box-pack: justify;
		justify-content: flex-start;
		background-color: #fff;
		background-color: var(--white);
		border-bottom: 1px solid #eaeaea;
		flex-flow: wrap;
		/*box-shadow: 0 2px 12px rgb(254 154 0 / 25%);*/

		.search-form-area-left {
			display: flex;
			flex-wrap: wrap;
			width: 90%;
			padding-right: 15px;

			@media (max-width: 992px) {
				width: 100%;
				padding-right: 0;
			}

			.Mehr-Suchoptionen {
				width: 100%;

				label {
					color: var(--black);
					font-size: 13px;
					text-transform: uppercase;
					font-weight: 400;
					letter-spacing: 1.5px;
				}

				ul {
					margin-bottom: 0;
				}

				.close-btn {
					position: absolute;
					right: 10px;
					top: 10px;
					width: 20px;
					height: 20px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: var(--link-font-color);
					color: #fff;
					border-radius: 3px;
					font-size: 12px;
				}
			}


			.advance-checkbox {
				border-top: 1px solid #f4f4f4;
				border-right: 1px solid #f4f4f4;
				width: 100%;
				margin: 0 auto;

				ul {
					display: flex;
					align-items: center;
					width: 100%;
					flex-wrap: wrap;
					padding: 15px 20px 5px 20px;
					margin: 0;

					li {
						margin: 0 0 0 0;
						width: 25%;

						@media all and (min-width: 993px) and (max-width: 1199px) {
							width: 50%;
						}

						label {
							font-size: 15px;
						}
					}
				}

			}
		}

		.search-form-area-right {
			width: 10%;

			@media (max-width: 992px) {
				width: 100%;
			}
		}

		.btn-group {
			width: 15%;
		}

		.form-flex {
			width: 25%;
			padding: 10px 22px 0 22px;
			border-right: 1px solid #f4f4f4;

			@media all and (min-width: 993px) and (max-width: 1199px) {
				width: 50%;
			}

			&.v2 {
				width: 45%;
			}

			@media (max-width:992px) {
				width: 100%;
				border-bottom: 1px solid #f4f4f4;
				border-right: none;
				padding: 2px 12px;

				&.v2 {
					width: 100%;
				}
			}

			.fa {
				position: absolute;
				right: 0;
				top: 10px;
			}

			.form-control {
				padding: 5px 0;
				background-color: var(--white);
				border: none;
				text-align: left;
				height: auto;
				min-height: 40px;
				padding-right: 20px;

			}

			>label {

				color: var(--black);
				font-size: 13px;
				text-transform: uppercase;
				display: block;
				font-weight: normal;
				letter-spacing: 1.5px;

				@media (max-width:992px) {
					display: none;
				}


			}

			.form-flex-area {
				position: relative;
			}
		}

		.input-first {
			@media (min-width: 1200px) {
				width: 20%;
			}

			@media all and (min-width: 993px) and (max-width: 1199px) {
				width: 50%;
			}
		}

		.input-sec {
			@media (min-width: 1200px) {
				width: 35%;
			}

			@media all and (min-width: 993px) and (max-width: 1199px) {
				width: 50%;
			}
		}

		.input-third {
			@media (min-width: 1200px) {
				width: 20%;
			}

			@media all and (min-width: 993px) and (max-width: 1199px) {
				width: 50%;
			}
		}



		.form-btn {
			width: 100%;
			display: flow-root;
			webkit-box-align: center;
			align-items: center;
			padding-right: 10px;

			@media (max-width:992px) {
				width: 100%;
				padding: 5px;
			}



			.btn {
				width: 101%;
				height: 100px;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 28px;

				@media (max-width:992px) {
					height: 40px;
					font-size: 20px;
					width: 100%;
				}
			}
		}
	}

	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--color-white);
	}

	.list-group-item {
		color: var(--font-color-main);
		text-decoration: none;

		&:hover {
			color: var(--font-color-main);
			text-decoration: none;
		}

		&.active {
			background-color: transparent;
			border: 1px solid var(--color-primary);
		}
	}
}