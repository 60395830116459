.static-page-layout-v1 {
	margin-top: var(--page-margin-top);
}

.pdf-bl {
	position: relative;
	padding-left: 50px;
	display: block;
	padding: 15px;
	box-shadow: 0 0px 8px 0 rgb(160 166 168 / 35%);
	padding-left: 130px;
	margin-bottom: 30px;

	.icon-pdf {
		background-image: url(../img/icon-pdf.png);
		width: 100px;
		height: 100px;
		display: inline-block;
		position: absolute;
		left: 20px;
		top: 10px;
	}

	span {
		display: block;
	}

	.btn {
		margin-top: 15px;
	}
}

#accordionExample {
	.card {
		margin-bottom: 20px;
		border-radius: 5px;
		border: 1px solid #ccc;
	}

	.btn {
		display: block;
		position: relative;
		width: 100%;
		text-align: left;
		padding: 0;
		font-size: 20px;
		color: var(--h1-font-color);
		text-transform: uppercase;

		&:hover {
			text-decoration: none;
		}

		&:after {
			position: absolute;
			right: 0;
			font-family: "Font Awesome 5 Free";
			font-weight: bold;
		}
	}

	button[aria-expanded="true"] {

		&:after {
			content: "\f068";
		}
	}

	button[aria-expanded="false"] {

		&:after {
			content: "\f067";

		}
	}
}